import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(
    private _httpClient: HttpClient
  ) { }
  apiUrl = 'Customer/';

  getCustomerDetailsByCompany(pagenumber:number,pagesize:number) {
    return this._httpClient.get(`${environment.companyApi}${this.apiUrl + 'GetCustomerDetailsCompany?pagenumber='+pagenumber+'&pagesize='+pagesize}`).pipe(take(1), pluck('data'));
  }
  getcustomerdetailsbyphone(Phonenumber: string) { return this._httpClient.get(`${environment.companyApi}${this.apiUrl + 'GetOrderCustomerInfoByPhone'}` + '?Phonenumber=' + Phonenumber).pipe(take(1), pluck('data')); }

  getOrderCustomerInfo(Phonenumber: string, Companyid: string) { return this._httpClient.get(`${environment.companyApi}${this.apiUrl + 'GetOrderCustomerInfoByPhone'}` + '?Phonenumber=' + Phonenumber + '&Companyid=' + Companyid).pipe(take(1), pluck('data')); }

  getcustomersbycompany = () => this._httpClient.get(`${environment.companyApi}${this.apiUrl + 'GetCustomersBycompany'}`).pipe(take(1), pluck('data'));

  getAllBlockedCustomer = () => this._httpClient.get(`${environment.companyApi}${this.apiUrl + 'GetAllBlockedCustomer'}`).pipe(take(1), pluck('data'));

  getcustomercompanybyid = () => this._httpClient.get(`${environment.companyApi}${this.apiUrl + 'GetCustomersCompanyById'}`).pipe(take(1), pluck('data'));

  GetAddressById = (addressid: string, customerid: string) => this._httpClient.get<any>(`${environment.companyApi}${this.apiUrl + 'GetCustomerAddressByAddressID?addressid=' + addressid + '&customerid=' + customerid}`).pipe(take(1), pluck('data'));

  updatecustomer(customer: any): Observable<any> { return this._httpClient.put(`${environment.companyApi}${this.apiUrl + 'update'}`, customer).pipe(take(1), pluck('data')); }

  updatecustomername(customer: any): Observable<any> { return this._httpClient.put(`${environment.companyApi}${this.apiUrl + 'updateName'}`, customer).pipe(take(1), pluck('data')); }


  createcustomer(customer: any): Observable<any> { return this._httpClient.post(`${environment.companyApi}${this.apiUrl + 'create'}`, customer).pipe(take(1), pluck('data')); }

  // createcustomeraddress(address: any): Observable<any> { return this._httpClient.post(`${environment.companyApi}${this.apiUrl + 'CreateAddress'}`, address).pipe(take(1), pluck('data')); } not used

  createCustomerAddress(address: any): Observable<any> { return this._httpClient.post(`${environment.companyApi}${this.apiUrl + 'CreateAddressDetails'}`, address).pipe(take(1), pluck('data')); }

  UpdateCustomerAddress(updatecustomer: any) { return this._httpClient.put(`${environment.companyApi}${this.apiUrl + 'UpdateCustomerAddress'}`, updatecustomer).pipe(take(1)); }

  getblacklistcustomersbycompany(companyid: any) { return this._httpClient.get(`${environment.companyApi}${this.apiUrl + 'GetBlacklistCustomersBycompany'}` + '?companyid=' + companyid).pipe(take(1), pluck('data')); }


  getCustomerAddressByCoords(id: any, lat: number, long: number) { return this._httpClient.get(`${environment.companyApi}${this.apiUrl + 'GetCustomersAddressByCoords'}` + '?CustomerId=' + id + '&Latitude=' + lat + '&Longtitude=' + long).pipe(take(1), pluck('data')); }

  /**********************************************/
  createRestaurantCustomer(customerData:any){
    return this._httpClient.post(`${environment.companyApi}${this.apiUrl + 'CreateRestaurantCustomer'}`, customerData).pipe(take(1), pluck('data'));
  }
  
  createRestaurantParams(Param:any){
    return this._httpClient.post(`${environment.companyApi}${this.apiUrl + 'CreateRestaurantParams'}`, Param).pipe(take(1));
  }
  getCustomerHeaders(){
    return this._httpClient.get(`${environment.companyApi}${this.apiUrl}GetCustomerStatusHeader`).pipe(take(1),pluck('data'));
  }

  getRestaurantParams(){
    return this._httpClient.get(`${environment.companyApi}${this.apiUrl}GetRestaurantParams`).pipe(take(1),pluck('data'));
  }



  getCustomers(pagenumber?:number, pagesize?:number,status?:number,searchterm?:string){

    return this._httpClient.get(`${environment.companyApi}${this.apiUrl}GetCustomers?${!!pagenumber?'pagenumber='+pagenumber+'&':''}${!!pagesize?'pagesize='+pagesize+'&':''}${!!searchterm?'searchterm='+searchterm+'&':''}${!!status?'status='+status:''}`).pipe(take(1),pluck('data'));
  }

  
  getStatusLookup(){
    return this._httpClient.get(`${environment.companyApi}${this.apiUrl}GetStatusLookup`).pipe(take(1),pluck('data'));
  }

  updatelookup(data:any) {
    return this._httpClient.put(`${environment.companyApi}${this.apiUrl + 'UpdateRLookUp'}`, data).pipe(take(1), pluck('data'));
   }
   deletelookup(sid:any) {
    return this._httpClient.delete(`${environment.companyApi}${this.apiUrl + 'DeleteRLookup'}?sid=${sid}`).pipe(take(1), pluck('data'));
   }
}
