import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  apiUrl: string = "Language";
  constructor(
    private _httpClient: HttpClient) { }

  public getLanguages(): Observable<any[]> {
    return this._httpClient.get<any[]>(`${environment.localizationApiUrl}${this.apiUrl + '/GetAll'}`).pipe(take(1),pluck('data'))
    // return this._httpClient.get<any[]>(`${this._config.localizationApiUrl}${this.apiUrl + '/GetAll'}`).pipe(pluck('data'),take(1))
  }
  public getcompanylanguages(): Observable<any[]> {
    return this._httpClient.get<any[]>(`${environment.companyApi}${'companyinfo​/languages'}`).pipe(take(1),pluck('data'));
  }

}
